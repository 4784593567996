import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import AnimationWrapper from "../components/animationWrapper"
import ContactForm from "../components/contactForm"
import ContactTile from "../components/contactTile"
import Container from "../components/container"
import ContactDataSection from "../components/contactDataSection"
import Header from "../components/header"
import InnerSection from "../components/innerSection"
import ContactMap from "../components/contactMap"
import ContactMapInteractive from "../components/contactMapInteractive"
import Seo from "../components/seo"

import "./kontakt.css"

const ContactPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <ContactMapInteractive contactData={data.contactData} contactProject={data.contactProject} regions={data.regions.nodes} />

      <ContactDataSection />

      <section id="contact-page-data" className="page-section">
        <Container>
          <InnerSection className="contact-data">
            <div className="contact-form-container">
              <AnimationWrapper>
                <h2 className="section-heading">Skontaktuj się z nami</h2>
              </AnimationWrapper>
              <ContactForm title="Czamaninek" />
            </div>
            <ContactTile />
          </InnerSection>
        </Container>
      </section>

      <ContactMap mapUrl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d77746.3862768788!2d18.661285103088705!3d52.48683815559017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b5c3c21e9263b%3A0x75e14e3a4b15b5e9!2sCzamaninek.%20Producent%20materia%C5%82%C3%B3w%20budowlanych!5e0!3m2!1sen!2spl!4v1667479442796!5m2!1sen!2spl" />

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "kontakt" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    contactData: allMdx(
      filter: {internal: {contentFilePath: {regex: "/contact-data/"}}}
    ) {
      nodes {
        frontmatter{
          name
          slug
          department
          order
          position
          email
          phone
          phone_alt
          phone_postfix
          region
          avatar {
            childImageSharp {
              gatsbyImageData(webpOptions: {quality: 95}, width: 110, height: 110)
            }
          }
        }
      }
    }
    contactProject: mdx(frontmatter: {department: {eq: "biuro-projektowe"}}) {
      frontmatter {
        name
        slug
        department
        address{
          street
          postal_code
          locality
          region
        }
        email
        phone
        phone_alt
        phone_postfix
        # avatar {
        #  childImageSharp {
        #     gatsbyImageData(webpOptions: {quality: 95}, width: 110, height: 110)
        #   }
        # }
      }
    }
    regions: allMdx(filter: {internal: {contentFilePath: {regex: "/contact-regions/"}}}) {
      nodes {
        frontmatter {
          title
          split
          contact_sales
          contact_service
          powiaty{
            name
            split
            contact_sales
            contact_service
            miejscowosci{
              name
              contact_sales
              contact_service
            }
          }
        }
      }
    }
  }
`

export default ContactPage
